// App.js
import React from 'react';
import './App.css';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import ProjectGrid from './components/ProjectGrid';
import Contact from './components/Contact';
import About from './components/About';
import ProjectPage from './components/ProjectPage';
import ProjectList from './components/ProjectList';

import projects from './projects.json'; 

const App = () => {
  return (
    <Router>
      <div className="app">
        <NavigationBar />
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/projects/:id" element={<ProjectPage projects={projects} />} />
          <Route path="/" element={<ProjectGrid projects={projects} />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
