// Contact.js
import React from "react";
import "./Contact.css";

const Contact = () => {
  const contactImage = 'assets/about/69.png';

  return (
    <div className="contact-container">
      <div className="contact-image">
        <img src={contactImage} alt="Contact Image" />
      </div>

      <div className="contact-text">
        <div className="email-address">
          <a href="mailto:andimazreku0@gmail.com">andimazreku0@gmail.com</a>
        </div>

        <div className="instagram-link">
          <a
            href="https://instagram.com/andibiotiker"
            target="_blank"
            rel="noopener noreferrer"
          >
            instagram
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
