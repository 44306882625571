// About.js
import React from 'react';
import './About.css';

const About = () => {
  const imageSource = 'assets/about/64.png';

  return (
    <div className="about">
      <div className="text-desc">
        <p>multimedia visual design and art</p>
        <p>communication design student</p>
        <p>2000</p>
        <p>berlin</p>

        <div className="exhibitions">
          <i>exhibitions</i>
          <ul>
            <li><a href="https://www.instagram.com/manifest.io.berlin/">MANIFEST:IO</a><br />Berlin, Germany<br />17.-18.02.2023</li>
            <li><a href="https://ars.electronica.art/who-owns-the-truth/en/scanopticon/">ARS Electronica Festival 2023</a><br />Linz, Austria<br />6.-10.09.2023</li>
            <li><a href="https://www.thf-berlin.de/aktuelles/100-jahre-100-stunden/veranstaltung/e-gentis-a-mazreku-scanopticon">100 Jahre Flughafen Tempelhof</a><br />Berlin, Germany<br />6.-10.10.2023</li>
          </ul>
        </div>
      </div>

      <div className="gallery">
        <div className="gallery-item">
          <img src={imageSource} alt="About Image" />
        </div>
      </div>
    </div>
  );
};

export default About;
